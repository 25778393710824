<template>
  <div>
    <!--    <Loader />-->
    <div class="wrapper">
      <router-view :key="$route.path" />
    </div>
  </div>
</template>
<script>
export default {
  name: "BlankLayout",
  components: {},
  mounted() {},
};
</script>
<style>
@import url("../assets/css/custom.css");
.modal-backdrop {
  opacity: 0.5;
}
</style>